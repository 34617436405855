<template>
  <div class="content-dialog">
    <v-dialog v-model="show" persistent max-width="550">
      <v-card class="edit-container">
        <v-card-title class="headline" t-data="headline">
          <h4>Select Content</h4>
        </v-card-title>
        <v-card-text t-data="card-text">
          <p class="edit-field-label sub-body-bold" t-data="info-label">
            Application Menu
          </p>
          <v-select
            outlined
            dense
            class="can-disable-selector mb-4"
            placeholder="Select Menu"
            v-model="bannerObject.section"
            :menu-props="{bottom: true, offsetY: true}"
            :items="sectionItems"
            @change="changeSection"
            return-object
            hide-details
          ></v-select>
          <v-alert
            v-if="isError"
            class="alert-date-container info800Color--text sub-body-regular mb-6"
            color="info200Color"
            icon="mdi-information"
            t-data="warning-alert"
            dense
          >
            Please select the content you want to display.</v-alert
          >
          <search
            v-if="hasSection"
            :search="search"
            class="mb-4"
            label="Search by Title"
            @input="textSearch"
            @clear="clearSearch()"
          ></search>
          <v-layout fluid v-resize="onResize">
            <v-data-table
              v-if="hasSection"
              :loading="sectionContent.isLoading"
              :options.sync="options"
              :headers="headers"
              :items="contentList"
              :items-per-page="sectionContent.pageSize"
              :server-items-length="sectionContent.totalSize"
              :footer-props="{'items-per-page-options': itemPerPages}"
              :sort-desc="true"
              :must-sort="false"
              class="elevation-1 custom-table"
              fixed-header
              single-select
              show-select
              style="width: 100%"
              height="350"
            >
              <template
                v-slot:item.data-table-select="{item}"
                style="width: 240px"
              >
                <v-radio-group
                  v-model="bannerObject.content_id"
                  @change="changeContent(item)"
                >
                  <v-radio
                    :value="item.id.toString()"
                    color="secondary"
                  ></v-radio>
                </v-radio-group>
              </template>
              <template v-slot:item.content="{item}" style="width: 240px">
                <v-list-item-content
                  class="d-inline-block text-truncate"
                  style="width: 180px"
                >
                  <v-list-item-title>
                    {{
                      item.content[0].code === 'th'
                        ? item.content[0].title
                        : item.content[1].title
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      item.content[0].code === 'en'
                        ? item.content[0].title
                        : item.content[1].title
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:item.org="{item}">{{
                listBranch(item.org)
              }}</template>
              <template v-slot:item.expiry_date="{item}">{{
                'expiry_date' in item ? dateFmt(item.expiry_date) : '-'
              }}</template>
            </v-data-table>
          </v-layout>
        </v-card-text>
        <v-card-actions t-data="card-action">
          <v-spacer></v-spacer>
          <v-btn
            class="cancel-button button-bold"
            t-data="cancel-btn"
            color="info600Color"
            text
            @click="closeDialog"
          >
            Cancel</v-btn
          >
          <v-btn
            class="confirm-button button-bold"
            t-data="confirm-btn"
            color="info600Color"
            text
            @click="confirm"
            :disabled="bannerObject.section.value === 0"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {convertDateBirthDate, searchHandler} from '../../helper/helper'
import Search from '@/components/Search'
import {sectionEnumText} from '@/constants/banner'
import _ from 'lodash'
export default {
  props: {
    show: {type: Boolean},
    isClear: {type: Boolean},
  },
  components: {
    Search
  },
  created() {
    // edit case
     if (this.bannerObject.content_id !== '') {
       let payload = {
         content_id: this.bannerObject.content_id.toString(),
         contents: this.bannerObject.linkedContent,
         expiry_date: this.bannerObject.end_date,
       }
       this.appliedSection = this.bannerObject.section
       this.appliedContent = payload
     }
  },
  computed: {
    hasSection() {
      return this.bannerObject.section.value !== 0
    },
    sectionItems() {
      const items = []
      for (let section of this.sections) {
        items.push({
          text: sectionEnumText[section.text],
          type: section.text,
          value: section.value
        })
      }
      return items
    },
    ...mapState('banner', [
      'bannerObject',
      'sections',
      'sectionContent',
      'contentList'
    ])
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    closeDialog() {
      this.bannerObject.section = this.appliedSection
      this.setLinkedContent(this.appliedContent)
      this.resetContentTable()
      this.$emit('close')
    },
    dateFmt: date => convertDateBirthDate(date),
    async fetch() {
      await this.fetchContentList()
    },
    resetData() {
      this.search = ''
      this.options = {
        page: 1,
        itemsPerPage: 25
      }
      this.selectedContent = {}
    },
    changeSection(value) {
      this.resetContent()
      this.resetContentTable()
      this.setSectionId(value.value)
      this.resetData()
    },
    listBranch(orgs) {
      return orgs.map(org => org.org_codename.toUpperCase()).join(', ')
    },
    changeContent(item) {
      this.selectedContent = item
    },
    confirm() {
      // case create
      if (!_.isEmpty(this.selectedContent)) {
        let payload = {
          content_id: this.selectedContent.id.toString(),
          contents: this.selectedContent.content,
          expiry_date: this.selectedContent.expiry_date,
        }
        this.appliedSection = this.bannerObject.section
        this.appliedContent = payload
        this.setLinkedContent(payload)
        this.$emit('close')
        this.$emit('resetDatetime', payload.expiry_date !== undefined)
        // case edit
      } else if (this.bannerObject.content_id !== '') {
        let payload = {
          content_id: this.bannerObject.content_id.toString(),
          contents: this.bannerObject.linkedContent,
          expiry_date: this.bannerObject.period_end_date,
        }
        this.appliedSection = this.bannerObject.section
        this.appliedContent = payload
        this.setLinkedContent(payload)
        this.$emit('close')
        this.$emit('resetDatetime', payload.expiry_date !== undefined)
      } else {
        this.isError = true
      }
    },
    textSearch(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, () => {
        this.setContentTextSearch(this.search.trim())
        this.fetch()
      })
    },
    clearSearch() {
      this.setContentTextSearch('')
      this.setContentPage(1)
      this.fetch()
    },
    ...mapActions('banner', [
      'fetchContentList',
      'resetContent',
      'resetContentTable',
      'resetEndDatetime',
      'resetSection',
      'setContentPage',
      'setContentPageSize',
      'setContentTextSearch',
      'setLinkedContent',
      'setSectionId'
    ])
  },
  watch: {
    options: {
      handler() {
        const {page, itemsPerPage} = this.options
        this.setContentPage(page)
        this.setContentPageSize(itemsPerPage)
        if (this.bannerObject.section.value !== 0) this.fetch()
      },
      deep: true
    },
    show() {
      if (this.bannerObject.section.value !== 0) this.fetch()
    },
    'bannerObject.content_id': {
      handler() {
        this.isError = false
      },
      deep: true
    },
    isClear() {
      if (this.isClear) {
        this.appliedSection = {text: '', value: 0 }
        this.appliedContent = {content_id: '', contents: [], expiry_date: undefined}
        this.$emit('clear')
        this.$emit('resetDatetime', false)
      }
    },
  },
  data: () => ({
    windowSize: {
      x: 0,
      y: 0
    },
    selectedContent: {},
    isError: false,
    search: '',
    options: {},
    itemPerPages: [25, 50, 100],
    headers: [
      {text: 'Title', value: 'content', sortable: false},
      {
        text: 'Branch',
        value: 'org',
        align: 'left',
        sortable: false,
        width: 89,
      },
      {
        text: 'Expiry Date',
        value: 'expiry_date',
        align: 'center',
        sortable: false,
        width: 120,
      }
    ],
    appliedSection: {text: '', value: 0 },
    appliedContent: {content_id: '', contents: [], expiry_date: undefined}
  })
}
</script>

<style lang="scss">
.alert-date-container {
  padding: 6px 10px;
}

.alert-date-container > .v-alert__wrapper > .theme--light.v-icon {
  color: var(--info-500-color);
  font-size: 1.25rem;
  margin-right: 10px;
}

.content-dialog > .v-dialog {
  max-height: 700px;
  height: 700px;
}
</style>
