<template>
  <quick-preview
    :is-preview="show"
    :show-close-btn="true"
    @input="closePreview"
  >
    <template v-slot:content-en>
      <preview-content :thumbnail="enContentObject.thumbnail" />
    </template>
    <template v-slot:content-th>
      <preview-content :thumbnail="thContentObject.thumbnail" />
    </template>
  </quick-preview>
</template>
<script>
import QuickPreview from '@/components/QuickPreview'
import PreviewContent from './PreviewContent'

export default {
  name: 'Preview',
  components: {
    QuickPreview,
    PreviewContent
  },
  props: {
    show: {type: Boolean, required: true},
    closePreview: {type: Function},
    dataObject: {type: Object}
  },
  data() {
    return {
      activeTab: 'th'
    }
  },
  computed: {
    enContentObject() {
      return this.dataObject.contents.filter(obj => {
        return obj.code == 'en'
      })[0]
    },
    thContentObject() {
      return this.dataObject.contents.filter(obj => {
        return obj.code == 'th'
      })[0]
    }
  }
}
</script>
