<template>
  <div>
    <v-row>
      <v-col cols="12" t-data="profile-image" class="py-0">
        <v-img :src="thumbnail" max-height="502"></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'PreviewContent',
  props: {
    thumbnail: {type: String},
  },
}
</script>
