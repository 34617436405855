<template>
  <v-container class="edit-container px-10" fluid>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px;">
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Slide Banner
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete()"
            :show-preview="true"
            :show-save="!disabledEdit"
            :disabled-preview="isDisabledPreview()"
            :on-remove="showDeletePopup"
            :on-preview="showPreview"
            :on-cancel="showCancelPopup"
            :on-save="save"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-confirm
      :show="isDelete"
      title="Remove Confirmation"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDelete"
      icon=""
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>
    <preview
      v-if="isPreview"
      :show="isPreview"
      :close-preview="closePreview"
      :data-object="bannerObject" />
    <!-- Form -->
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            Slide Banner Information
          </h6>
          <v-spacer></v-spacer>
          <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
            Status
          </p>
          <v-switch
            color="primary"
            v-model="bannerObject.status"
            :disabled="disabledEdit || disableEmptyBranch || isExpired"
            :false-value="1"
            :true-value="0"
            class="mb-0 pb-0"
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-0">
          <v-row align="start" justify="start" class="mt-0">
            <v-col cols="6">
              <p
                class="body-regular warning-content mb-6"
                t-data="warning-content"
              >
                All fields are required.
              </p>
            </v-col>
          </v-row>
          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6" t-data="info-input">
              <single-selected-branch
                outlined
                required
                dense
                title="Branch"
                :isDisabled="
                  disabledEdit || isUnpublished || isPublished || isExpired
                "
                :branch="branches"
                :selectedBranch="selectedBranch"
                :syncData="syncSelectedData"
                :isEditMode="isEditMode"
                @change="changeBranch"
              ></single-selected-branch>
            </v-col>
          </v-row>
          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="12" t-data="info-input">
              <v-radio-group
                v-model="bannerObject.link_to"
                class="mt-0"
                row
                hide-details
                @change="changeLinkTo"
              >
                <v-radio
                  v-for="item in typeItems"
                  :key="item.value"
                  :value="item.value"
                  :label="item.text"
                  :disabled="
                    disabledEdit ||
                      disableEmptyBranch ||
                      isUnpublished ||
                      isPublished ||
                      isExpired
                  "
                  color="secondary"
                  >{{ item }}</v-radio
                >
              </v-radio-group>
            </v-col>
          </v-row>
          <div
            t-data="external-container"
            v-if="bannerObject.link_to === typeEnum.LINK_TO_WEBSITE"
          >
            <v-row align="start" justify="start" class="mt-0 px-2">
              <v-col
                t-data="website-warning-alert"
                cols="12"
                class="sub-body-bold secondary-text--text mt-2 mb-6 d-flex align-content-center"
              >
                <v-icon size="13" class="mr-1" left light>
                  mdi-information
                </v-icon>
                Link to external source (URL)
              </v-col>
            </v-row>

            <v-row align="start" justify="start" class="mt-0 px-2">
              <v-col cols="6" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  Display Type
                </p>
                <v-select
                  outlined
                  dense
                  class="can-disable-selector"
                  placeholder="Select Display Type"
                  :items="sectionItems"
                  v-model="bannerObject.section"
                  :rules="sectionRules"
                  :disabled="disabledEdit || isExpired"
                  return-object
                  @change="changeDisplayType"
                ></v-select>
              </v-col>
              <v-col cols="6" v-if="!isPopupImage" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  URL
                </p>
                <v-text-field
                  outlined
                  required
                  dense
                  class="can-disable-selector"
                  placeholder="Enter your external URL here. (ex. https://www.google.co.th)"
                  v-model="bannerObject.browser_url"
                  :rules="urlRules"
                  :disabled="disabledEdit || isExpired"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div
            t-data="internal-container"
            v-if="bannerObject.link_to === typeEnum.LINK_WITHIN_APP"
          >
            <v-row align="start" justify="start" class="mt-0 px-2">
              <v-col
                t-data="website-warning-alert"
                cols="12"
                class="sub-body-bold secondary-text--text mt-2 mb-6 d-flex align-content-center"
              >
                <v-icon size="13" class="mr-1" left light>
                  mdi-information
                </v-icon>
                Select internal link from Princ Health Application
              </v-col>
            </v-row>

            <v-row align="start" justify="start" class="mt-0 px-2">
              <v-col cols="6" t-data="info-input" class="mt-2">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  Link to Content
                </p>
                <v-text-field
                  outlined
                  required
                  dense
                  :append-icon="
                    sectionInfo.length > 0 ? undefined : 'mdi-chevron-right'
                  "
                  class="can-disable-selector"
                  placeholder="Select a content"
                  :value="sectionInfo"
                  :disabled="disabledEdit || disableEmptyBranch || isExpired"
                  @click="clickLinkToContent"
                  @click:clear="clearLinkContent"
                  readonly
                  clearable
                  :rules="showSelectContent ? [] : linkToContentRules"
                ></v-text-field>
                <content-dialog
                  :key="componentKey"
                  :show="showSelectContent"
                  :is-clear="isClearContentDialog"
                  @close="showSelectContent = false"
                  @resetDatetime="resetPublishDateTime"
                  @clear="isClearContentDialog = true"
                ></content-dialog>
              </v-col>
              <v-col cols="3" t-data="info-input">
                <p
                  class="edit-field-label sub-body-bold mb-4"
                  t-data="info-label"
                >
                  Content Title TH
                </p>
                <span :class="['body-regular', linkContentClass]">{{
                  linkContentTitleTh
                }}</span>
              </v-col>
              <v-col cols="3" t-data="info-input">
                <p
                  class="edit-field-label sub-body-bold mb-4"
                  t-data="info-label"
                >
                  Content Title EN
                </p>
                <span :class="['body-regular', linkContentClass]">{{
                  linkContentTitleEn
                }}</span>
              </v-col>
            </v-row>
          </div>
          <v-card
            v-if="isPopupImage"
            class="border-card mb-6 mx-2"
            t-data="popup-image-container"
          >
            <v-card-text>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6">
                  <h6 class="mb-6 card-title" t-data="thai-info-header">
                    Thai Content
                  </h6>
                </v-col>
                <v-col cols="2">
                  <h6 class="mb-6 card-title" t-data="eng-info-header">
                    English Content
                  </h6>
                </v-col>
                <v-col t-data="checkbox-duplicate">
                  <v-checkbox
                    v-model="duplicatePopupImage"
                    :disabled="disabledEdit || disableEmptyBranch || isExpired"
                    hide-details
                    label="Duplicate Thai content"
                    color="secondary"
                    class="check-box-text body-regular mt-0 pt-0"
                    @click="duplicateThaiPopupImage"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 mb-7">
                <v-col cols="4" t-data="thai-info-content">
                  <div>
                    <p
                      class="edit-field-label sub-body-bold ml-3"
                      t-data="info-label"
                    >
                      Popup Image
                    </p>
                    <image-dropzone
                      class="ml-3"
                      :thumbnail="bannerObject.contents[0].slide_image"
                      :is-error="isImageThPopupError"
                      :error-msg="imageThPopupErrorMessage"
                      @update="updatePopupImageTh"
                      @remove="removePopupImageTh"
                      :disabled="disabledEdit || isExpired"
                    ></image-dropzone>
                  </div>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="4" t-data="eng-info-content">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Popup Image
                  </p>
                  <image-dropzone
                    :thumbnail="bannerObject.contents[1].slide_image"
                    :is-error="isImageEnPopupError"
                    :error-msg="imageEnPopupErrorMessage"
                    @update="val => updatePopupImage('en', val)"
                    @remove="removePopupImageEn"
                    :disabled="disabledEdit || isExpired"
                  ></image-dropzone>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <h6 class="mt-6 mb-3 card-title" t-data="publish-datetime-title">
            Publish Date And Time
          </h6>
          <v-form
            ref="datetimeForm"
            t-data="publish-datetime-form"
            lazy-validation
          >
            <v-row align="start" justify="start" class="mt-0 px-2">
              <v-col cols="3" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  Publish Start Date
                </p>
                <custom-date-picker
                  placeholder="Select Start Date"
                  v-model="bannerObject.period_start_date"
                  :isDisable="
                    disabledEdit ||
                      disableEmptyBranch ||
                      isPublished ||
                      isExpired
                  "
                  :rule="publishStartDateRules"
                  @input="startDateChange"
                  outlined
                  required
                ></custom-date-picker>
              </v-col>
              <v-col cols="3" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  Start Time
                </p>
                <time-picker
                  placeholder="Select Start Time"
                  v-model="bannerObject.period_start_time"
                  @input="startTimeChange"
                  :isDisable="
                    disabledEdit ||
                      disableEmptyBranch ||
                      isPublished ||
                      isExpired
                  "
                  :rule="startTimeRules"
                  outlined
                  required
                ></time-picker>
              </v-col>
              <v-col cols="3" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  Publish End Date
                </p>
                <custom-date-picker
                  placeholder="Select End Date"
                  v-model="bannerObject.period_end_date"
                  @input="endDateChange"
                  :isDisable="
                    disabledEdit ||
                      disableEmptyBranch ||
                      disableHasExpiredDate ||
                      isExpired
                  "
                  :rule="publishEndDateRules"
                  :allowedDates="allowedDates"
                  outlined
                  required
                ></custom-date-picker>
              </v-col>
              <v-col cols="3" t-data="info-input">
                <p class="edit-field-label sub-body-bold" t-data="info-label">
                  End Time
                </p>
                <time-picker
                  placeholder="Select End Time"
                  v-model="bannerObject.period_end_time"
                  :isDisable="
                    disabledEdit ||
                      disableEmptyBranch ||
                      disableHasExpiredDate ||
                      isExpired
                  "
                  :rule="endTimeRules"
                  outlined
                  required
                ></time-picker>
              </v-col>
            </v-row>
          </v-form>

          <v-card class="border-card mt-6 mb-4">
            <v-card-text>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6">
                  <h6 class="mb-6 card-title" t-data="thai-info-header">
                    Thai Content
                  </h6>
                </v-col>
                <v-col cols="2">
                  <h6 class="mb-6 card-title" t-data="eng-info-header">
                    English Content
                  </h6>
                </v-col>
                <v-col t-data="checkbox-duplicate">
                  <v-checkbox
                    v-model="duplicateContent"
                    :disabled="disabledEdit || disableEmptyBranch || isExpired"
                    hide-details
                    label="Duplicate Thai content"
                    color="secondary"
                    class="check-box-text body-regular mt-0 pt-0"
                    @click="duplicateThaiContent"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0">
                <v-col cols="6" t-data="thai-info-content">
                  <content-by-lang
                    class="px-0 ml-3"
                    lang="th"
                    :title="bannerObject.contents[0].title"
                    :url="bannerObject.contents[0].thumbnail"
                    :disabledEdit="
                      disabledEdit || disableEmptyBranch || isExpired
                    "
                    :setImage="setImage"
                    :setImageError="setImageError"
                    :isImageError="isImageThError"
                    :imageErrorMessage="imageThErrorMessage"
                    :required-title="true"
                    :required-image="true"
                    :show-required-icon="false"
                    cols-image="12"
                    cols-title="12"
                    order-image="2"
                    order-title="1"
                    @changeUrl="changeUrlTh"
                    @changeTitle="changeTitleTh"
                    @removeImage="removeImageTh"
                  ></content-by-lang>
                </v-col>
                <v-col cols="6" t-data="eng-info-content">
                  <content-by-lang
                    class="px-0"
                    lang="en"
                    :disabledEdit="
                      disabledEdit || disableEmptyBranch || isExpired
                    "
                    :title="bannerObject.contents[1].title"
                    :url="bannerObject.contents[1].thumbnail"
                    :setImage="setImage"
                    :setImageError="setImageError"
                    :isImageError="isImageEnError"
                    :imageErrorMessage="imageEnErrorMessage"
                    :required-title="true"
                    :required-image="true"
                    :show-required-icon="false"
                    cols-image="12"
                    cols-title="12"
                    order-image="2"
                    order-title="1"
                    @changeTitle="changeTitleEn"
                    @removeImage="removeImageEn"
                  ></content-by-lang>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row class="mr-0 mb-3" style="margin-left: 1px; margin-right: 0;">
      <created-detail
        v-if="isEditMode"
        :data-object="bannerObject"
        :create-date="bannerObject.publish_date"
      />
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="isEditMode && hasPermDelete()"
        :show-preview="true"
        :show-save="!disabledEdit"
        :disabled-preview="isDisabledPreview()"
        :on-remove="showDeletePopup"
        :on-preview="showPreview"
        :on-cancel="showCancelPopup"
        :on-save="save"
      />
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import {mapState, mapActions} from 'vuex'

import AlertConfirm from '@/components/AlertConfirm'
import ContentByLang from '@/components/ContentByLang'
import ContentDialog from './ContentDialog'
import CreatedDetail from '@/components/CreatedDetail'
import CustomDatePicker from '@/components/DatePickerWithMin'
import EditButtons from '@/components/EditButtons'
import ImageDropzone from '@/components/ImageDropzone'
import PopupLoading from '@/components/PopupLoading'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import TimePicker from '@/components/TimePicker'
import Preview from '@/components/banner/Preview'
import {actionEnum} from '@/constants/constants'
import {hasPermissionsByOrgs, isUrl} from '../../helper/helper'
import {sectionEnumText, typeEnum, typeText} from '@/constants/banner'
import {sectionsWithExpired} from '@/constants/banner'
import {timeMoreThanCurrentRule, timeMoreThanStartRule} from '@/helper/rule'

export default {
  components: {
    'v-alert-confirm': AlertConfirm,
    'v-popup-loading': PopupLoading,
    ContentByLang,
    ContentDialog,
    CreatedDetail,
    CustomDatePicker,
    EditButtons,
    ImageDropzone,
    SingleSelectedBranch,
    TimePicker,
    Preview
  },
  computed: {
    sectionItems() {
      const items = []
      for (let section of this.sections) {
        items.push({
          text: sectionEnumText[section.text],
          value: section.value
        })
      }
      return items
    },
    typeItems() {
      return Object.keys(typeEnum).map(t => ({
        value: typeEnum[t],
        text: typeText[typeEnum[t]]
      }))
    },
    disableEmptyBranch() {
      return this.selectedBranch <= 0
    },
    disableHasExpiredDate() {
      return (
        this.bannerObject.period_end_date !== '' &&
        this.bannerObject.period_end_time !== '' &&
        sectionsWithExpired.includes(this.bannerObject.section.type)
      )
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditBanner', [this.selectedBranch])
      )
    },
    branches() {
      return this.orgObject.map(o => o.codename)
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    hasLinkedContent() {
      return !this.bannerObject.linkedContent
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', link: true, to: '/dashboard'},
        {text: 'Slide Banner', link: true, to: '/banners'},
        {text: `${this.textMode} Slide Banner`}
      ]
    },
    isPopupImage() {
      const popupSections = this.sections.filter(v => v.text === 'popupImage')
      return popupSections.length > 0
        ? popupSections[0].value === this.bannerObject.section_id
        : false
    },
    linkContentClass() {
      if (this.bannerObject.linkedContent.length > 0) {
        return 'primary600Color--text'
      } else {
        return 'disableTextColor--text'
      }
    },
    linkContentTitleTh() {
      if (this.bannerObject.linkedContent.length > 0) {
        return this.bannerObject.linkedContent[0].code === 'th'
          ? this.bannerObject.linkedContent[0].title
          : this.bannerObject.linkedContent[1].title
      } else {
        return 'Content Title will appear here.'
      }
    },
    linkContentTitleEn() {
      if (this.bannerObject.linkedContent.length > 0) {
        return this.bannerObject.linkedContent[0].code === 'en'
          ? this.bannerObject.linkedContent[0].title
          : this.bannerObject.linkedContent[1].title
      } else {
        return 'Content Title will appear here.'
      }
    },
    sectionInfo() {
      const hasSection = this.bannerObject.section.value !== 0
      const hasContent = this.bannerObject.linkedContent.length > 0
      if (hasSection && hasContent) {
        const sectionName = this.bannerObject.section.text
        const contentName =
          this.bannerObject.linkedContent[0].code === 'en'
            ? this.bannerObject.linkedContent[0].title
            : this.bannerObject.linkedContent[1].title
        return `${sectionName} > ${contentName}`
      } else {
        return ''
      }
    },
    ...mapState('auth', ['user']),
    ...mapState('banner', [
      'bannerObject',
      'imageEnErrorMessage',
      'imageEnPopupErrorMessage',
      'imageThErrorMessage',
      'imageThPopupErrorMessage',
      'isBrowser',
      'isDelete',
      'isExpired',
      'isImageEnError',
      'isImageEnPopupError',
      'isImageThError',
      'isImageThPopupError',
      'isLoading',
      'isPublished',
      'isUnpublished',
      'orgObject',
      'sections',
      'selectedBranch'
    ])
  },

  methods: {
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeleteBanner', [this.selectedBranch])
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.duplicateContent = false
      this.duplicatePopupImage = false
      this.resetState()
      this.$refs.form.resetValidation()
      this.$refs.datetimeForm.resetValidation()
      this.$router.push({
        name: 'banner-list'
      })
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    closePreview() {
      this.isPreview = false
    },
    isDisabledPreview() {
      const popupImageError =
        this.isPopupImage &&
        (this.isImageEnPopupError ||
          this.isImageThPopupError ||
          this.bannerObject.contents[0].slide_image === null ||
          this.bannerObject.contents[1].slide_image === null)
      if (
        this.isSectionError ||
        this.isUrlError ||
        this.isLinkToContentError ||
        this.isPublishStartDateError ||
        this.isStartTimeError ||
        this.isPublishEndDateError ||
        this.isEndTimeError ||
        this.isImageThError ||
        this.isImageEnError ||
        this.bannerObject.contents[0].title === null||
        this.bannerObject.contents[0].title === ''||
        this.bannerObject.contents[1].title === null ||
        this.bannerObject.contents[1].title === '' ||
        this.bannerObject.contents[0].thumbnail === '' ||
        this.bannerObject.contents[1].thumbnail === '' ||
        popupImageError
      ) {
        return true
      }
      return false
    },
    showPreview() {
      this.isPreview = true
    },
    async confirmDelete() {
      const isSuccess = await this.deleteBanner(this.$route.params.id)
      if (isSuccess) {
        this.$router.push({
          name: 'banner-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup() {
      this.setDeletePopup(true)
    },
    checkThaiContentExpand() {
      if (this.isThaiContentExpand) {
        this.isThaiContentExpand = false
      } else {
        this.isThaiContentExpand = true
      }
    },
    checkEngContentExpand() {
      if (this.isEngContentExpand) {
        this.isEngContentExpand = false
      } else {
        this.isEngContentExpand = true
      }
    },
    popupImageRules(lang, val) {
      if (this.isEditMode) {
        this.setPopupImageError({lang, isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val !== null) {
          return
        }
        this.setPopupImageError({
          lang,
          isError: true,
          message: 'Popup Image is required'
        })
        return
      } else {
        if (!val || val === null) {
          this.setPopupImageError({
            lang,
            isError: true,
            message: 'Popup Image is required'
          })
          return
        }
        this.setPopupImageError({lang, isError: false, message: ''})
        return
      }
    },
    imageRules(lang, val) {
      if (this.isEditMode) {
        this.setImageError({lang, isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setImageError({
          lang,
          isError: true,
          message: 'Image is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setImageError({
            lang,
            isError: true,
            message: 'Image is required'
          })
          return
        }
        this.setImageError({lang, isError: false, message: ''})
        return
      }
    },
    duplicateThaiContent() {
      if (this.duplicateContent) {
        this.bannerObject.contents[1].title = this.bannerObject.contents[0].title
        this.bannerObject.contents[1].thumbnail = this.bannerObject.contents[0].thumbnail
      } else {
        this.bannerObject.contents[1].title = null
        this.bannerObject.contents[1].thumbnail = ''
      }
      this.imageRules(
        this.bannerObject.contents[1].code,
        this.bannerObject.contents[1].thumbnail
      )
    },
    duplicateThaiPopupImage() {
      if (this.duplicatePopupImage) {
        this.bannerObject.contents[1].slide_image = this.bannerObject.contents[0].slide_image
      } else {
        this.bannerObject.contents[1].slide_image = null
      }
      this.popupImageRules(
        this.bannerObject.contents[1].code,
        this.bannerObject.contents[1].slide_image
      )
    },
    updatePopupImage(lang, file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.setPopupImageError({
            lang,
            isError: true,
            message: 'Only support image file!'
          })
          return
        } else if (!file || file.size > 1040000) {
          this.setPopupImageError({
            lang,
            isError: true,
            message: 'Image size should be less than 1 MB!'
          })
          return
        } else {
          this.setPopupImage({lang, file})
          this.setPopupImageError({lang, isError: false, message: ''})
          return
        }
      } else {
        this.bannerObject.browser_url = ''
      }
    },
    updatePopupImageTh(val) {
      this.updatePopupImage('th', val)
      if (this.duplicatePopupImage) {
        this.setPopupImage({lang: 'en', file: val})
        this.setPopupImageError({lang: 'en', isError: false, message: ''})
      }
    },
    removePopupImageTh() {
      this.bannerObject.contents[0].slide_image = null
      this.setPopupImageError({
        lang: 'th',
        isError: true,
        message: 'Popup Image is required'
      })
      if (this.duplicatePopupImage) {
        this.bannerObject.contents[1].slide_image = null
        this.popupImageRules(
          this.bannerObject.contents[1].code,
          this.bannerObject.contents[1].slide_image
        )
      }
    },
    removePopupImageEn() {
      this.bannerObject.contents[1].slide_image = null
      this.setPopupImageError({
        lang: 'en',
        isError: true,
        message: 'Popup Image is required'
      })
    },
    changeUrlTh(val) {
      if (this.duplicateContent) {
        this.setImage({lang: 'en', file: val})
        this.setImageError({lang: 'en', isError: false, message: ''})
      }
    },
    removeImageTh() {
      this.bannerObject.contents[0].thumbnail = ''
      if (this.duplicateContent) {
        this.bannerObject.contents[1].thumbnail = ''
        this.imageRules(
          this.bannerObject.contents[1].code,
          this.bannerObject.contents[1].thumbnail
        )
      }
    },
    removeImageEn() {
      this.bannerObject.contents[1].thumbnail = ''
    },
    changeTitleTh(val) {
      this.bannerObject.contents[0].title = val
      if (this.duplicateContent) {
        this.bannerObject.contents[1].title = val
      }
    },
    changeTitleEn(val) {
      this.bannerObject.contents[1].title = val
    },
    resetStartDatetimeWithCheckStatus() {
      // create mode && edit unpublished mode will allow to resetStartDatetime
      if (!this.isEditMode) {
        this.resetStartDatetime()
      } else {
        if (this.isUnpublished) {
          this.resetStartDatetime()
        }
      }
    },
    resetError() {
      this.isSectionError = false
      this.isUrlError = false
      this.isLinkToContentError = false
      this.isImageThPopupError = false
      this.isImageEnPopupError = false
    },
    changeDisplayType(val) {
      this.setSectionId(val.value)
      this.bannerObject.browser_url = ''
      this.resetPopupImage()
      this.duplicatePopupImage = false
      this.resetStartDatetimeWithCheckStatus()
      this.resetEndDatetime()
      this.$refs.form.resetValidation()
      this.$refs.datetimeForm.resetValidation()
      this.resetError()
    },
    closeAlert() {
      this.isError = false
      this.isSuccess = false
    },
    resetContentDialogComponent() {
      this.componentKey++
    },
    resetPublishDateTime(hasExpiry) {
      if (!hasExpiry) {
        this.resetEndDatetime()
      }
      this.resetStartDatetimeWithCheckStatus()
      this.$refs.datetimeForm.resetValidation()
    },
    async save() {
      if (this.validate()) {
        if (!this.isEditMode) {
          this.isSuccess = await this.create()
          if (this.isSuccess) {
            this.$refs.form.resetValidation()
            this.resetState()
            this.fetchSections()
            this.$router.push({
              name: 'banner-list',
              query: {isCreateSuccess: true, snackTitle: 'created'}
            })
            return
          } else {
            this.isError = true
          }
        } else {
          this.isSuccess = await this.update(this.$route.params.id)
          if (this.isSuccess) {
            this.$router.push({
              name: 'banner-list',
              query: {isCreateSuccess: true, snackTitle: 'edited'}
            })
          } else {
            this.isError = true
          }
        }
      }
    },
    resetContent() {
      if (this.isBrowser) this.unlinkContent()
    },
    unlinkContent() {
      this.resetContent()
      this.setLinkedContent({
        content_id: '',
        contents: null
      })
    },
    async changeLinkTo(value) {
      this.resetAll()
      this.resetError()
      if (typeEnum.LINK_WITHIN_APP === value) {
        this.setBrowserMode(false)
        await this.fetchSections()
      }
      if (typeEnum.LINK_TO_WEBSITE === value) {
        this.setBrowserMode(true)
        await this.fetchSections()
      }
    },
    startDateChange(event) {
      if (event && !this.disableHasExpiredDate) {
        this.bannerObject.period_end_date = ''
        this.bannerObject.period_start_time = ''
        this.bannerObject.period_end_time = ''
      }
    },
    startTimeChange(event) {
      if (event && !this.disableHasExpiredDate) {
        this.bannerObject.period_end_time = ''
      }
    },
    endDateChange(event) {
      if (event && !this.disableHasExpiredDate) {
        this.bannerObject.period_end_time = ''
      }
    },
    allowedDates(val) {
      return moment(this.bannerObject.period_start_date).isSameOrBefore(
        moment(val)
      )
    },
    startDateRule(val) {
      if (moment(this.bannerObject.period_end_date).isBefore(moment(val))) {
        return 'The start date must be less than the end date.'
      } else {
        return true
      }
    },
    startTimeMoreThanCurrentRule(val) {
      return this.isPublished ||
        this.isExpired ||
        this.disabledEdit ||
        this.disableEmptyBranch
        ? true
        : timeMoreThanCurrentRule(val, this.bannerObject.period_start_date)
    },
    startTimeMoreThanEndTimeRule(val) {
      return this.disableHasExpiredDate
        ? timeMoreThanStartRule(
            this.bannerObject.period_end_time,
            val,
            this.bannerObject.period_start_date,
            this.bannerObject.period_end_date,
            'The start time must be less than the end time.'
          )
        : true
    },
    endTimeRule(val) {
      if (this.disableHasExpiredDate) {
        return true
      } else {
        return timeMoreThanStartRule(
          val,
          this.bannerObject.period_start_time,
          this.bannerObject.period_start_date,
          this.bannerObject.period_end_date
        )
      }
    },
    validate() {
      this.popupImageRules(
        this.bannerObject.contents[0].code,
        this.bannerObject.contents[0].slide_image
      )
      this.popupImageRules(
        this.bannerObject.contents[1].code,
        this.bannerObject.contents[1].slide_image
      )
      this.imageRules(
        this.bannerObject.contents[0].code,
        this.bannerObject.contents[0].thumbnail
      )
      this.imageRules(
        this.bannerObject.contents[1].code,
        this.bannerObject.contents[1].thumbnail
      )

      const popupImageError =
        this.isPopupImage &&
        (this.isImageEnPopupError ||
          this.isImageThPopupError ||
          this.bannerObject.contents[0].slide_image === null ||
          this.bannerObject.contents[1].slide_image === null)

      const validDatetime = this.$refs.datetimeForm.validate()

      if (
        this.$refs.form.validate() &&
        validDatetime &&
        !this.isImageEnError &&
        !this.isImageThError &&
        !popupImageError
      ) {
        return true
      } else {
        this.isError = true
        return false
      }
    },
    resetAll() {
      this.resetSection()
      this.resetContent()
      this.resetContentTable()
      this.resetContentDialogComponent()
      this.resetPopupImage()
      this.duplicatePopupImage = false
      this.resetStartDatetime()
      this.resetEndDatetime()
      this.$refs.form.resetValidation()
      this.$refs.datetimeForm.resetValidation()
    },
    changeBranch() {
      this.resetAll()
      this.resetError()
    },
    syncSelectedData(data) {
      if (data.length > 0) this.setSelectedBranch(data[0])
      this.bannerObject.orgs = data.map(org => ({
        codename: org,
        selected: true
      }))
    },
    clickLinkToContent() {
      this.showSelectContent = true
    },
    clearLinkContent() {
      if (this.isPublished) {
        this.resetEndDatetime()
      } else {
        this.resetStartDatetime()
        this.resetEndDatetime()
      }
      this.$refs.datetimeForm.resetValidation()
      this.resetSection()
      this.resetContent()
      this.resetContentTable()
      this.isClearContentDialog = true
    },
    ...mapActions('banner', [
      'create',
      'deleteBanner',
      'fetchSections',
      'resetBrowserMode',
      'resetContent',
      'resetContentTable',
      'resetEndDatetime',
      'resetPopupImage',
      'resetSection',
      'resetStartDatetime',
      'resetState',
      'setBannerId',
      'setBrowserMode',
      'setDeletePopup',
      'setEditMode',
      'setImage',
      'setImageError',
      'setLinkedContent',
      'setOrgInfo',
      'setPopupImage',
      'setPopupImageError',
      'setSectionId',
      'setSelectedBranch',
      'update'
    ])
  },

  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)
    if (this.isEditMode) {
      await this.setBannerId(this.$route.params.id)
      await this.fetchSections()
    }
    const isViewOnly =
      this.isEditMode &&
      !hasPermissionsByOrgs('CanEditBanner', [this.selectedBranch])
    await this.setOrgInfo(isViewOnly)
  },
  beforeMount() {
    this.resetState()
    this.resetSection()
    this.resetContent()
    this.resetContentTable()
  },
  data() {
    return {
      subTitle:
        'Are you sure you want to remove this slide banner? This action will remove content in both Thai and English.',
      isThaiContentExpand: true,
      isEngContentExpand: true,
      duplicateContent: false,
      duplicatePopupImage: false,
      isError: false,
      isSuccess: false,
      isEditMode: false,
      isSectionError: false,
      isUrlError: false,
      isLinkToContentError: false,
      isPublishStartDateError: false,
      isStartTimeError: false,
      isPublishEndDateError: false,
      isEndTimeError: false,
      sectionRules: [
        v => {
          if (!this.isBrowser) {
            this.isSectionError = false
            return true
          }
          if (v.value) {
            this.isSectionError = false
            return true
          }
          this.isSectionError = true
          return 'Display Type is required'
        }
      ],
      urlRules: [
        v => {
          if (v === '') {
            this.isUrlError = true
            return 'URL is required'
          }
          if (!isUrl(v)) {
            this.isUrlError = true
            return 'URL is invalid format'
          }
          this.isUrlError = false
          return true
        }
      ],
      linkToContentRules: [
        v => {
          if (v) {
            this.isLinkToContentError = false
            return true
          }
          this.isLinkToContentError = true
          return 'Link to Content is required'
        }
      ],
      publishStartDateRules: [
        v => {
          if (v) {
            this.isPublishStartDateError = false
            return true
          }
          this.isPublishStartDateError = true
          return 'Date is required'
        },
        v => {
          const result = this.startDateRule(v)
          if (typeof result === 'string') {
            this.isPublishStartDateError = true
            return result
          }
          return result
        }
      ],
      startTimeRules: [
        v => {
          if (v) {
            this.isStartTimeError = false
            return true
          }
          this.isStartTimeError = true
          return 'Time is required'
        },
        v => {
          const result = this.startTimeMoreThanCurrentRule(v)
          if (typeof result === 'string') {
            this.isStartTimeError = true
            return result
          }
          return result
        },
        v => {
          const result = this.startTimeMoreThanEndTimeRule(v)
          if (typeof result === 'string') {
            this.isStartTimeError = true
            return result
          }
          return result
        }
      ],
      publishEndDateRules: [
        v => {
          if (v) {
            this.isPublishEndDateError = false
            return true
          }
          this.isPublishEndDateError = true
          return 'Date is required'
        }
      ],
      endTimeRules: [
        v => {
          if (v) {
            this.isEndTimeError = false
            return true
          }
          this.isEndTimeError = true
          return 'Time is required'
        },
        v => {
          const result = this.endTimeRule(v)
          if (typeof result === 'string') {
            this.isEndTimeError = true
            return result
          }
          return result
        }
      ],
      showSelectContent: false,
      typeEnum: typeEnum,
      isClearContentDialog: false,
      componentKey: 0,
      isCancel: false,
      isPreview: false
    }
  }
}
</script>

<style scoped>
.form .col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
